<template>
  <div>
    <div class="tw-flex tw-gap-6">
      <div class="tw-w-9/12">
        <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl">
          <TaskTipTapEditor v-model="publication.description" />
        </div>
      </div>

      <div class="tw-w-3/12">
        <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl">
          <b-form-group :label="$t('Title')">
            <b-form-input
              v-model="publication.title"
              :placeholder="$t('Write the title of the publication here...')"
              block
            />
          </b-form-group>
          <!--          <b-form-group :label="$t('Category')">-->
          <!--            <b-form-select v-model="publication.category" :placeholder="$t('Enter category')" />-->
          <!--          </b-form-group>-->
          <b-form-group :label="$t('Group')">
            <v-select v-model="publication.group" :options="groups" :placeholder="$t('Select group')" label="title" />
          </b-form-group>

          <button
            class="tw-w-full tw-bg-slate-100 tw-text-slate-600 tw-px-3 tw-py-2 tw-rounded-lg hover:tw-bg-slate-500 hover:tw-text-slate-100 tw-border tw-border-slate-100 hover:tw-border-slate-200 tw-transition"
            @click="createArticle"
          >
            {{ $t('Create publication') }}
          </button>

          <a
            :href="$router.resolve({name: 'knowledge-base'}).href"
            class="tw-mt-2 tw-block tw-text-center tw-w-full tw-text-red-700 tw-px-3 tw-py-2 tw-rounded-lg hover:tw-bg-red-50 tw-border tw-border-white tw-transition"
            >{{ $t('Cancel') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormGroup, BFormInput} from 'bootstrap-vue'
import WikiService from '@/services/wiki.service'
import vSelect from 'vue-select'
import TaskTipTapEditor from '@/components/Task/TaskTipTapEditor.vue'

export default {
  components: {
    TaskTipTapEditor,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      groups: [],
      publication: {
        title: '',
        description: '',
        group: null,
      },
      value: null,
      editor: null,
    }
  },
  mounted() {
    WikiService.getGroups().then(r => (this.groups = r.data))
  },
  methods: {
    createArticle() {
      WikiService.createArticle({
        ...this.publication,
        group: this.publication.group.id,
      }).then(r => {
        this.$router.push({
          name: 'knowledge-base-publication',
          params: {slug: r.data.id},
        })
      })
    },
  },
}
</script>

<style lang="scss"></style>