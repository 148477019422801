import axiosIns, {auth} from '@/libs/axios';

class WikiService {
  getGroups() {
    return axiosIns.get('wiki/', {
      headers: auth(),
    });
  }

  getGroup(id) {
    return axiosIns.get('wiki/' + id + '/', {
      headers: auth(),
    });
  }

  createGroup(data) {
    return axiosIns.post('wiki/create/', data, {headers: auth()});
  }

  updateGroup({id, ...data}) {
    return axiosIns.patch(`wiki/update/${id}/`, data, {headers: auth()});
  }

  deleteGroup(id) {
    return axiosIns.delete(`wiki/delete/${id}/`, {headers: auth()});
  }

  getArticle(id) {
    return axiosIns.get(`wiki/article/${id}/`, {headers: auth()});
  }

  createArticle(data) {
    return axiosIns.post('wiki/article/create/', data, {headers: auth()});
  }

  updateArticle(id, data) {
    return axiosIns.patch(`wiki/article/update/${id}/`, data, {headers: auth()});
  }

  deleteArticle(id) {
    return axiosIns.delete(`wiki/article/delete/${id}/`, {headers: auth()});
  }
}

export default new WikiService();
